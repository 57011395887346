import React from "react";
import theme from "theme";
import { Theme, Text, Box, Input, Link, Button, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakt"} />
		<Helmet>
			<title>
				Kontaktieren Sie uns | Forygia Pub
			</title>
			<meta name={"description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:title"} content={"Kontaktieren Sie uns | Forygia Pub"} />
			<meta property={"og:description"} content={"Wo sich sportliche Leidenschaften und kulinarische Genüsse vereinen!"} />
			<meta property={"og:image"} content={"https://forygia.com/img/home-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://forygia.com/img/icon.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://forygia.com/img/icon.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://forygia.com/img/icon.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://forygia.com/img/icon.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" position="relative" quarkly-title="Form-3">
			<Override slot="SectionContent" max-width="1220px" />
			<Box
				z-index="1"
				left="0px"
				top="0px"
				right="auto"
				bottom="auto"
				position="static"
				grid-template-columns="repeat(2, 1fr)"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						lg-padding="0px 0px 0px 0px"
						sm-font="normal 700 36px/1.2 --fontFamily-sans"
						margin="0px 0px 18px 0px"
						font="--headline1"
						color="--darkL1"
						text-align="left"
						padding="0px 0 0px 0px"
						lg-width="100%"
						lg-margin="0px 0px 25px 0px"
					>
						Kontaktieren Sie uns
					</Text>
					<Text margin="0px 50% 50px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0 50px 0px">
						Haben Sie Fragen oder möchten Sie eine Reservierung vornehmen? Wenden Sie sich noch heute an uns! Unser engagiertes Team steht Ihnen zur Seite, um Ihren Besuch zu einem außergewöhnlichen Erlebnis zu machen.
					</Text>
				</Box>
				<Box
					position="relative"
					z-index="1"
					display="flex"
					lg-flex-direction="column"
					flex-direction="row"
				>
					<Components.QuarklycommunityKitNetlifyForm lg-margin="0px 0px 35px 0px" sm-margin="0px 0px 25px 0px">
						<Box display="flex" margin="0px 0px 15px 0px" flex-direction="column">
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								margin="0px 0px 20px 0px"
								sm-flex-direction="column"
							>
								<Box
									min-width="10px"
									min-height="10px"
									margin="0px 15px 0px 0px"
									sm-margin="0px 0 15px 0px"
									lg-width="50%"
									sm-width="100%"
								>
									<Text margin="0px 0px 8px 0px" font="normal 400 16px/1.5 --fontFamily-sans">
										Vorname
									</Text>
									<Input
										margin="0px 10px 0px 0px"
										padding="12px 16px 12px 16px"
										width="100%"
										font="--lead"
										md-max-width="none"
										border-radius="8px"
										name="First Name"
										type="text"
										required
										md-margin="0px 0 16px 0px"
										background="rgba(255, 255, 255, 0.1)"
										border-color="--color-lightD2"
									/>
								</Box>
								<Box min-width="10px" min-height="10px" lg-width="50%" sm-width="100%">
									<Text margin="0px 0px 8px 0px" font="normal 400 16px/1.5 --fontFamily-sans">
										Nachname
									</Text>
									<Input
										margin="0px 0 0px 0px"
										padding="12px 16px 12px 16px"
										width="100%"
										font="--lead"
										md-max-width="none"
										border-radius="8px"
										name="Last Name"
										type="text"
										required
										md-margin="0px 0 16px 0px"
										background="rgba(255, 255, 255, 0.1)"
										border-color="--color-lightD2"
									/>
								</Box>
							</Box>
							<Text margin="0px 0px 8px 0px" font="normal 400 16px/1.5 --fontFamily-sans">
								Nachricht
							</Text>
							<Input
								margin="0px 10px 20px 0px"
								padding="12px 16px 52px 16px"
								width="100%"
								font="--lead"
								md-max-width="none"
								border-radius="8px"
								name="Message"
								type="text"
								required
								md-margin="0px 0 16px 0px"
								background="rgba(255, 255, 255, 0.1)"
								border-color="--color-lightD2"
							/>
							<Components.QuarklycommunityKitCheckbox margin="0px 0px 20px 0px">
								<Override slot="Input" border-color="--color-dark" />
								<Override slot="Text">
									Ihre Daten sind uns wichtig. Lesen Sie unsere{" "}
									<Link href="/privacy-policy">
										Datenschutzrichtlinie
									</Link>
								</Override>
							</Components.QuarklycommunityKitCheckbox>
							<Button
								padding="11px 74px 11px 74px"
								font="normal 400 20px/1.5 --fontFamily-sans"
								sm-margin="10px 0px 0px 0px"
								sm-width="100%"
								focus-box-shadow="none"
								color="--light"
								background="--color-secondary"
								border-radius="8px"
								hover-background="--color-darkL1"
								hover-color="--light"
								hover-transition="background-color 0.5s ease 0s"
								transition="background-color 0.1s ease 0s"
								margin="0px 0px 15px 0px"
								hover-border-width="0px"
								hover-border-style="none"
								max-width="350px"
								text-align="center"
								align-self="center"
							>
								Senden Sie
							</Button>
						</Box>
					</Components.QuarklycommunityKitNetlifyForm>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 50px 0px 80px"
						border-color="#d9dee3"
						width="50%"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-border-width="0 0 1px 0"
						md-padding="0px 0 30px 0px"
						justify-content="flex-start"
						lg-padding="0px 0 0px 0"
						lg-width="auto"
						align-self="auto"
					>
						<Box min-width="100px" min-height="100px">
							<Box padding="16px 16px 16px 16px" md-width="100%" color="--dark">
								<Box>
									<Box
										sm-padding="64px 0 0 0"
										margin="32px 0 0 0"
										max-width="360px"
										position="relative"
										padding="0 0 0 64px"
									>
										<Icon
											size="48px"
											top="0"
											left="0"
											category="md"
											icon={MdLocationOn}
											position="absolute"
										/>
										<Text as="h4" margin="6px 0" font="--base">
											Adresse
										</Text>
										<Text as="p" margin="6px 0" font="--headline3">
											Herzbergstraße 79/Haus 30, 10365 Berlin, Deutschland
										</Text>
									</Box>
									<Box
										position="relative"
										padding="0 0 0 64px"
										sm-padding="64px 0 0 0"
										margin="64px 0 0 0"
										max-width="360px"
									>
										<Icon
											top="0"
											left="0"
											category="md"
											icon={MdEmail}
											position="absolute"
											size="48px"
										/>
										<Text font="--base" as="h4" margin="6px 0">
											Email
										</Text>
										<Text as="p" margin="6px 0" font="--headline3" color="--darkL2">
											<Link href="mailto:info@forygia.com" text-decoration="none" hover-text-decoration="underline" color="--darkL2">
												info@forygia.com
											</Link>
										</Text>
									</Box>
									<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
										<Icon
											left="0"
											category="md"
											icon={MdPhone}
											position="absolute"
											size="48px"
											top="0"
										/>
										<Text font="--base" as="h4" margin="6px 0">
											Telefon
										</Text>
										<Text as="p" margin="6px 0" font="--headline3">
											+49 30 141 98975
										</Text>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});